import React from 'react';
import { navigate, graphql, useStaticQuery } from 'gatsby';
import IntroPage from 'src/px/IntroPage';
import { locale } from 'src/utils';

const QUERY = graphql`
  query SlasherFaq {
    en: allGraphCmsFaq(filter: { contexts: { in: health_slasher }, locale: { eq: en } }) {
      nodes {
        slug
        title
        explanation
      }
    }
    es: allGraphCmsFaq(filter: { contexts: { in: health_slasher }, locale: { eq: es } }) {
      nodes {
        slug
        title
        explanation
      }
    }
  }
`;

/** @todo staticQuery */
const SlasherIntroView = ({}) => {
  const data = useStaticQuery(QUERY);

  return (
    <IntroPage
      flow="Slasher"
      onNext={() => navigate('/slasher/zip')}
      faqs={data?.[locale]?.nodes}
    />
  );
};

export default SlasherIntroView;
